.checkIcon {
  width: 30px;
  height: 30px;
}

.warningIcon {
  width: 30px;
  height: 30px;
  color: #ee8523;
}

.xMarkIcon {
  width: 25px;
  height: 25px;
}

.error {
  color: #d70000;
}

.warning {
  color: #ee8523;
}

.info {
  color: #3bafda;
}

.success {
  color: #17a700;
}
