.listLinks {
  max-width: 600px;
  padding-left: 14px;
}

.listLinksWrap {
  display: inline-block;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
}
