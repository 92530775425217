body {
  margin: 0;
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family:
    source-code-pro,
    Menlo,
    Monaco,
    Consolas,
    'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.show {
  display: block !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #282c34;
  color: white;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #61dafb;
}

.filter {
  &__control {
    border-color: #495561 !important;
    margin: 0.125rem auto;
    background-color: #414d59 !important;
    border-radius: 0.2rem !important;

    &--is-focused {
      border-color: #495561 !important;
      background-color: #495561 !important;
      box-shadow: none !important;
    }
  }

  &__input {
    color: #a2afbe !important;
  }

  &__single-value {
    color: #a2afbe !important;
  }

  &__option {
    &--is-focused {
      background: #269dc9 !important;
      color: white !important;
    }

    &--is-selected {
      background: #269dc9 !important;
      color: white !important;
    }
  }

  &__menu {
    border: 1px solid #414d59 !important;
    background: #414d59 !important;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.tooltips-bubble {
  z-index: 100 !important;
}
