.dividerContainer {
  display: flex;
  height: 50px;
  align-items: center;
  padding: 10px 12px;
  background-color: #414d59;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.textTitle {
  color: #3bafda;
  font-weight: 500;
}

.wraperPanel {
  padding: 8px 0 8px 16px;
}
