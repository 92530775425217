.tableCellWrapper {
  width: 50%;
  text-align: left;
  white-space: nowrap;

  > :not(:first-child) {
    margin-left: 12px;
  }
}

.wrapperExtraInfo {
  display: flex;
  width: 100%;
  flex-direction: column;
}
