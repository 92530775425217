.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.txHeaderLeft {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.txIdContainer {
  display: flex;
  align-items: center;
  gap: 8px;

  .title {
    margin-bottom: 0;
    font-size: 30px;
  }
}

.txInfoContainer {
  display: flex;
  align-items: center;
  gap: 10px;

  .badge {
    font-size: 18px;
  }

  .infoIcon {
    width: 25px;
    height: 25px;
  }

  .tooltip {
    width: 300px;
  }
}

.namesContainer {
  display: flex;
  gap: 50px;
}

.nameLabels {
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.nameLabel {
  margin-bottom: 0;
  color: #8a929a;
  font-size: 24px;
  font-weight: 300;
}

.nameLabelValue {
  margin-bottom: 0;
  font-size: 24px;
}

.containerValues {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

.containerValue {
  display: flex;
  gap: 0.3rem;
}

.loaderContainer {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
}

.loaderContainerRow {
  display: flex;
  width: 100%;
  gap: 1rem;
}

.actionButtons {
  display: flex;
  gap: 1rem;
}
