.tableValidations {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}

.cellComment {
  width: 50px;
  word-wrap: break-word;
}
