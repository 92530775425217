.container {
  display: flex;
  align-items: center;
  gap: 40px;

  @media (max-width: 1500px) {
    max-width: 1000px;
    padding: 34px 50px;
  }
}

.loaderContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
