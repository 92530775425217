.swipper-container {
  max-width: 800px;
}

.infoIcon {
  width: 15px;
  height: 15px;
  margin-right: 5px;
}

.divider {
  border: 0;
  border-top: 1px solid #495561;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
