.sliderContainer {
  position: fixed;
  z-index: 2;
  right: 0;
  display: flex;
  overflow: scroll;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  padding: 30px 32px;
  background-color: #323b44;
  box-shadow: -2px 0 8px 0 rgba(0, 0, 0, 0.3);
  transition: width 0.3s ease;
}

.sliderContainerOpen {
  width: 400px;
  align-items: baseline;

  @media (max-width: 1500px) {
    width: 500px;
  }
}

.arrow {
  cursor: pointer;
}

.arrowReversed {
  transform: rotate(180deg);
}

.contentContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 10px;
}
