/* TODO: Make it modular this styles and not be affected because of external styles */
.navbar {
  position: fixed !important;
  z-index: 1000;
  top: 0;
  overflow: visible;
  width: 100%;
  background: #323b44;

  &--logo {
    margin-right: 15px;
    cursor: pointer;
  }

  &--brand {
    display: flex;
    align-items: center;
    color: #fff;
  }

  &--label-container {
    display: flex;
    align-items: center;
    color: #fff;
    gap: 8px;
    text-decoration: none;

    &-label {
      font-size: 1.3rem;

      &:hover {
        color: #fff;
      }
    }
  }
}

.sign-out {
  a {
    padding: 0;
  }
}
