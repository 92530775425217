.validatorContainer {
  display: flex;
  width: 360px;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px 10px 30px 30px;
  background-color: #576778;
  border-radius: 8px;
  cursor: pointer;
  opacity: 0.25;
  transition: opacity 0.1s ease-in-out;

  &:hover {
    opacity: 1;
  }

  @media (max-width: 1500px) {
    padding: 10px 10px 20px 20px;
  }
}

.selectedValidatorContainer {
  opacity: 1;
}

.headerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.validatorInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 8px;

  .validatorTitle {
    font-size: 22px;
    font-weight: 600;
  }

  .validatorBadge {
    width: fit-content;
    font-size: 13px;
  }
}

.infoIcon {
  width: 24px;
  height: 24px;
}
