.container {
  padding: 30px 24px;
}

.contentPageTxDetail {
  display: grid;
  padding-top: 0;
  padding-right: 0;
  grid-template-columns: 1fr 90px;
  transition: grid-template-columns 0.3s ease;
}

.contentPageTxDetailOpen {
  grid-template-columns: 1fr 560px;

  @media (max-width: 1500px) {
    grid-template-columns: 900px 560px;
  }
}
