@import '../../assets/scss/theme.scss';

.sidebar {
  position: fixed;
  z-index: 999;
  top: 56px;
  display: inline-flex;
  overflow: hidden;
  width: 200px;
  height: 100vh;
  padding-right: 0;
  padding-left: 0;
  border-right: 1px solid #495561;
  background: #323b44;
  box-shadow: 4px 4px 6px 0 rgba(0, 0, 0, 0.2);

  .arrow {
    float: right;
  }

  .fa.arrow::before {
    content: "\f104";
  }

  .active > a > .fa.arrow {
    transform: rotate(-90deg);
  }

  .nav li {
    position: relative;

    a {
      display: block;
      padding: 10px 15px;
      color: #fff;
      font-size: $font-size-sm;
      overflow-x: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      svg {
        margin-right: 6px;
      }

      @include hover-focus {
        background-color: #495561;
        text-decoration: none;
      }

      &.active {
        background-color: #343a40;

        @include hover-focus {
          background-color: #495561;
        }

        &::before {
          position: absolute;
          z-index: 2;
          top: 0;
          left: 0;
          width: 5px;
          height: 100%;
          background: #037afe;
          content: ' ';
        }
      }
    }
  }

  .nav > li {
    & > .nav > li {
      border-bottom: none;

      & > a {
        padding-left: 37px;
      }

      & > .nav > li {
        border-bottom: none;

        & > a {
          padding-left: 52px;
        }
      }
    }
  }

  ul {
    flex-direction: column;
  }

  ul li {
    border-bottom: 1px solid #495561;
  }

  a:focus {
    text-decoration: none;
  }

  .nav {
    width: 100%;
    margin-top: 27px;
  }

  &Small {
    width: 55px;

    @media (max-width: 1200px) {
      width: 200px !important;
    }
  }

  @media (max-width: 1200px) {
    width: 55px;
  }
}

.sidebarLabel {
  display: inline;

  &Small {
    display: none;

    @media (max-width: 1200px) {
      display: inline !important;
    }
  }

  @media (max-width: 1200px) {
    display: none;
  }
}
